import React from "react";
import { TiTick } from "react-icons/ti";
import Image from "next/image";
import Link from "next/link";
import { FaWhatsapp, FaCalendarAlt, FaClock, FaChalkboardTeacher, FaCheckCircle } from "react-icons/fa";
import { SiGooglemeet } from "react-icons/si";
import { HiOutlineExternalLink } from "react-icons/hi";

const EventCard = ({
  title,
  subtitle,
  date,
  dayOfWeek,
  time,
  speaker,
  speakerAvatarUrl,
  eventJoinLink,
  topics,
}) => {
  return (
    <div className="event-card">
      {/* Card background with improved contrast */}
      <div className="absolute inset-0 bg-gradient-to-br from-blue-900 via-indigo-900 to-blue-900 opacity-95 rounded-2xl"></div>
      
      {/* Decorative elements */}
      <div className="absolute top-0 right-0 w-40 h-40 bg-blue-400 rounded-full filter blur-3xl opacity-20 -translate-y-1/2 translate-x-1/2"></div>
      <div className="absolute bottom-0 left-0 w-40 h-40 bg-purple-400 rounded-full filter blur-3xl opacity-20 translate-y-1/2 -translate-x-1/2"></div>
      
      <div className="relative z-10 p-6 md:p-8">
        {/* Event header with improved visibility */}
        <div className="text-center mb-6">
          {/* Free webinar badge with better contrast */}
          <div className="flex justify-center gap-3 mb-3">
            <span className="inline-block px-3 py-1 bg-blue-700 text-white text-xs font-semibold rounded-full border border-blue-500 shadow-md">WEBINAR</span>
            <span className="inline-block px-3 py-1 bg-green-700 text-white text-xs font-semibold rounded-full border border-green-500 shadow-md">FREE</span>
          </div>
          
          {/* Title with improved visibility */}
          <h2 className="text-white font-bold text-xl md:text-3xl mb-3 text-shadow-lg">
            {title}
          </h2>
          
          {/* Subtitle with better contrast */}
          <p className="text-white text-sm md:text-base max-w-3xl mx-auto mt-3">
            {subtitle}
          </p>
        </div>
        
        {/* Event date and time with improved visibility */}
        <div className="flex items-center justify-center mb-8">
          <div className="event-time-badge">
            <div className="flex items-center justify-center space-x-6 md:space-x-10">
              <div className="flex items-center">
                <FaClock className="text-blue-300 mr-2" />
                <span className="text-white">{time}</span>
              </div>
              <div className="flex items-center">
                <FaCalendarAlt className="text-blue-300 mr-2" />
                <span className="text-white">{dayOfWeek}, {date}</span>
              </div>
            </div>
          </div>
        </div>
        
        {/* Content section with improved layout */}
        <div className="flex flex-col md:flex-row md:items-start md:justify-between gap-8">
          {/* Left side: Topics with better visibility */}
          <div className="md:w-1/2">
            <h3 className="text-white font-semibold mb-4 flex items-center">
              <span className="w-8 h-8 rounded-full bg-blue-700 flex items-center justify-center mr-2">
                <FaCheckCircle className="text-blue-200" />
              </span>
              What You'll Learn
            </h3>
            <ul className="space-y-3">
              {topics.map((topic, index) => (
                <li key={index} className="flex items-start bg-blue-800 bg-opacity-50 rounded-lg p-3 transform transition-transform duration-300 hover:translate-x-1 hover:bg-opacity-70 border border-blue-600">
                  <div className="flex-shrink-0 w-6 h-6 rounded-full bg-green-600 bg-opacity-70 flex items-center justify-center mr-3 mt-0.5">
                    <FaCheckCircle className="text-white text-sm" />
                  </div>
                  <span className="text-white text-sm md:text-base">
                    {topic}
                  </span>
                </li>
              ))}
            </ul>
            
            {/* Action buttons with improved visibility */}
            <div className="flex flex-wrap items-center justify-start gap-4 mt-6">
              <Link
                href="https://chat.whatsapp.com/ClWl4daXrSpFi0cHftk0pl"
                target="_blank"
                className="event-action-button bg-green-600 hover:bg-green-700 group"
              >
                <FaWhatsapp className="text-xl mr-2" />
                <span>Join Community</span>
              </Link>
              
              <Link
                href={eventJoinLink}
                target="_blank"
                className="event-action-button bg-blue-600 hover:bg-blue-700 group"
              >
                <SiGooglemeet className="text-xl mr-2" />
                <span>Join Webinar</span>
                <HiOutlineExternalLink className="ml-1 opacity-0 group-hover:opacity-100 transition-opacity duration-300" />
              </Link>
            </div>
          </div>
          
          {/* Right side: Speaker with improved card */}
          <div className="md:w-1/2 flex flex-col items-center">
            <div className="speaker-card">
              <div className="speaker-image-container">
                <Image
                  className="speaker-image"
                  src={speakerAvatarUrl}
                  width={300}
                  height={350}
                  alt={speaker}
                />
              </div>
              <div className="speaker-info">
                <h3 className="speaker-name">{speaker}</h3>
                <div className="speaker-badge">Featured Speaker</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventCard;
