"use client";
import React, { useState, useEffect, useRef } from "react";
import { useRouter } from "next/navigation";
import { FaFacebookF, FaWhatsapp, FaYoutube, FaArrowRight } from "react-icons/fa";
import { TypewriterEffect } from "../../ui/TypeWriterEffect";
import axios from "axios";
import { prodServerUrl } from "@/app/utils/globals";
import "primereact/resources/themes/lara-light-cyan/theme.css";
import Link from "next/link";
import TransparentButton from "@/components/layout/Button/TransparentButton";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import "./hero.css";

// Register GSAP plugins
if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

const HeroSection = () => {
  const [disable, setDisable] = useState(false);
  const heroRef = useRef(null);
  const contentRef = useRef(null);
  const formRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    selectedCourse: "",
  });
  const [error, setError] = useState("");
  
  // Animation setup
  useEffect(() => {
    const ctx = gsap.context(() => {
      // Content animation
      gsap.fromTo(
        contentRef.current.children,
        { y: 50, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.8,
          stagger: 0.2,
          ease: "power3.out",
        }
      );

      // Form animation
      gsap.fromTo(
        formRef.current,
        { x: 50, opacity: 0 },
        {
          x: 0,
          opacity: 1,
          duration: 0.8,
          delay: 0.5,
          ease: "power3.out",
        }
      );
    }, heroRef);

    return () => ctx.revert();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const router = useRouter();
  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, phone, selectedCourse } = formData;

    if (!name || !phone || !selectedCourse) {
      setError("All Fields Are Necessary");
      return;
    }

    try {
      setDisable(true);
      const res = await axios.post(`${prodServerUrl}/hero-forms`, formData);

      if (res.status === 201) {
        setError("");
        setFormData({
          name: "",
          phone: "",
          selectedCourse: "",
        });
        router.push("/thank-you");
      }
    } catch (error) {
      console.log(error);
      setError("Something went wrong. Please try again.");
    } finally {
      setDisable(false);
    }
  };

  const words = [
    {
      text: "Your",
    },
    {
      text: "Online",
    },
    {
      text: "Finishing",
    },
    {
      text: "School",
    },
    {
      text: "Is",
    },
    {
      text: "Vidyastu.",
      className: "text-orange-200 dark:text-orange-400",
    },
  ];
  
  return (
    <div ref={heroRef} className="hero-section w-full h-[700px] md:h-[650px] bg-cover bg-center bg-hero-pattern relative overflow-hidden">
      {/* Background overlay with gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-slate-900/90 to-slate-800/90 backdrop-brightness-[0.7]"></div>
      
      {/* Animated particles */}
      <div className="particles-container absolute inset-0 z-0"></div>
      
      <div className="relative z-10 w-full h-full flex flex-col-reverse md:flex-row justify-around items-start">
        {/* Main content */}
        <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56">
          {/* Hero content */}
          <div ref={contentRef} className="text-center mt-0 font-Poppins font-semibold">
            <div className="typewriter-container">
              <TypewriterEffect words={words} />
            </div>
            
            <h1 className="text-white text-sm md:text-base text-start mt-7 font-light leading-relaxed">
              Vidyastu empowers careers with high-quality, interactive courses,
              currently having{" "}
              <span className="text-[#FFD966] font-semibold student-count">9500+</span> students.
            </h1>
            
            <div className="text-start mt-8">
              <Link href="/about">
                <button className="explore-button px-6 py-3 bg-[#1DB954] text-white font-medium rounded-full transition-all duration-300 flex items-center gap-2 hover:bg-[#19a34a] hover:shadow-lg hover:translate-y-[-2px]">
                  <span>Explore Us</span>
                  <FaArrowRight className="text-sm" />
                </button>
              </Link>
            </div>
          </div>
        </div>
        
        {/* Form container */}
        <div className="container px-6 py-8 m-auto md:py-14 md:px-16 lg:px-56">
          <div ref={formRef} className="form-container text-center font-Poppins p-6 rounded-xl backdrop-blur-[12px] bg-white/5 border border-white/10 shadow-xl">
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col justify-center items-center">
                <h1 className="text-zinc-100 text-2xl md:text-3xl mb-8 text-start font-bold">
                  Learn New Age Skills Online
                </h1>
              </div>

              {error && (
                <div className="error-message text-red-400 text-sm mb-4 bg-red-900/20 py-2 px-3 rounded">
                  {error}
                </div>
              )}

              <div className="mb-4 font-Poppins">
                <select
                  id="selectedCourse"
                  name="selectedCourse"
                  value={formData.selectedCourse}
                  onChange={handleChange}
                  className="form-select w-full py-3 px-4 text-gray-700 bg-white/90 border border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DB954]/50 focus:border-[#1DB954] transition-all duration-200 text-sm"
                >
                  <option value="">Select A Course</option>
                  <option value="cyber security">Cyber Security and Ethical Hacking</option>
                  <option value="springboot">Java Springboot</option>
                  <option value="ai & ml">Artificial Intelligence and Machine Learning</option>
                  <option value="ui ux">UI/UX & Graphic Design</option>
                  <option value="digital marketing">Digital Marketing</option>
                  <option value="data science">Data Science & Analytics</option>
                  <option value="content writing">Content Writing</option>
                  <option value="web development">Full Stack Web Development</option>
                </select>
              </div>
              
              <div className="mb-4 font-Poppins">
                <input
                  id="name"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  type="text"
                  className="form-input w-full py-3 px-4 text-gray-700 bg-white/90 border border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DB954]/50 focus:border-[#1DB954] transition-all duration-200 text-sm"
                  placeholder="Your Name"
                  required
                />
              </div>
              
              <div className="mb-4 font-Poppins">
                <input
                  id="phone"
                  name="phone"
                  type="number"
                  value={formData.phone}
                  onChange={handleChange}
                  className="form-input w-full py-3 px-4 text-gray-700 bg-white/90 border border-white/20 rounded-lg focus:outline-none focus:ring-2 focus:ring-[#1DB954]/50 focus:border-[#1DB954] transition-all duration-200 text-sm"
                  placeholder="Your Phone"
                  required
                />
              </div>
              
              <div className="text-center font-Poppins">
                <button
                  className="submit-button w-full py-3 px-4 bg-[#1DB954] text-white font-medium rounded-lg transition-all duration-300 hover:bg-[#19a34a] hover:shadow-lg disabled:opacity-70 disabled:cursor-not-allowed"
                  type="submit"
                  disabled={disable}
                >
                  {disable ? "Submitting..." : "Submit"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
