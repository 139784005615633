"use client";

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { prodServerUrl } from "@/app/utils/globals";
import OurFreeCourseCard from "./OurFreeCourseCard";
import freecourseshome from "../faculty/data/freecourseshome";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { FaGraduationCap } from "react-icons/fa";
import './free-courses.css';

const FreeCourses = () => {
  const sectionRef = useRef(null);
  const headingRef = useRef(null);
  const cardsRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const ctx = gsap.context(() => {
      // Animate the heading
      gsap.fromTo(headingRef.current,
        { y: 30, opacity: 0 },
        {
          scrollTrigger: {
            trigger: headingRef.current,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse"
          },
          y: 0,
          opacity: 1,
          duration: 0.8,
          ease: "power3.out"
        }
      );

      // Animate the cards
      const cards = cardsRef.current.children;
      gsap.fromTo(cards,
        { y: 50, opacity: 0 },
        {
          scrollTrigger: {
            trigger: cardsRef.current,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse"
          },
          y: 0,
          opacity: 1,
          duration: 0.8,
          stagger: 0.2,
          ease: "power3.out"
        }
      );
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  return (
    <section ref={sectionRef} className="py-16 bg-gradient-to-b from-white via-blue-50/30 to-white pattern-bg">
      <div className="container mx-auto px-4 md:px-8 max-w-7xl">
        {/* Section Header */}
        <div ref={headingRef} className="text-center mb-12">
          <div className="flex items-center justify-center mb-4">
            <div className="h-[1px] w-12 bg-blue-600"></div>
            <span className="mx-4 text-blue-600 font-semibold text-sm uppercase tracking-wider">Learn for free</span>
            <div className="h-[1px] w-12 bg-blue-600"></div>
          </div>
          
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4 flex items-center justify-center">
            <FaGraduationCap className="text-blue-600 mr-3" />
            Free Courses
          </h2>
          
          <p className="text-gray-600 max-w-2xl mx-auto">
            Expand your knowledge with our collection of free courses. Learn at your own pace and discover new skills.
          </p>
        </div>

        {/* Cards Grid */}
        <div 
          ref={cardsRef}
          className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8 justify-items-center"
        >
          {freecourseshome.map((item) => (
            <OurFreeCourseCard
              key={item.id}
              title={item.coursetitle}
              thumbnail={item.image}
              resourceUrl={item.playlist}
              faculty={item.faculty}
            />
          ))}
        </div>

        {/* View All Button */}
        <div className="text-center mt-12">
          <a 
            href="/free-courses" 
            className="inline-flex items-center px-6 py-3 border border-blue-600 text-blue-600 bg-transparent hover:bg-blue-600 hover:text-white rounded-lg transition-colors duration-300 font-medium"
          >
            View All Free Courses
            <svg className="ml-2 w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14 5l7 7m0 0l-7 7m7-7H3"></path>
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
};

export default FreeCourses;
