import React, { useEffect, useRef } from 'react';
import Image from 'next/image';
import Link from "next/link";
import { RxDownload } from "react-icons/rx";
import { GiGraduateCap } from "react-icons/gi";
import { TbClockFilled } from "react-icons/tb";
import { GrCertificate } from "react-icons/gr";
import gsap from 'gsap';

const OurCourseCard = ({
  thumbnail,
  discountedPrice,
  duration,
  title,
  brochureUrl,
  courseredirect,
}) => {
  const cardRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && cardRef.current) {
      gsap.fromTo(
        cardRef.current,
        { 
          y: 30, 
          opacity: 0 
        },
        { 
          y: 0, 
          opacity: 1, 
          duration: 0.6, 
          ease: 'power2.out',
          delay: 0.1 * Math.random(), // Staggered animation
        }
      );
    }
  }, []);

  return (
    <div 
      ref={cardRef} 
      className="course-card"
    >
      {/* Course image */}
      <div className="course-image-container">
        <img
          src={thumbnail}
          alt={title}
          className="course-image"
          width={400}
          height={225}
        />
        {discountedPrice > 0 && <div className="premium-badge">Premium</div>}
      </div>

      {/* Course content */}
      <div className="course-content">
        <h3 className="course-title">{title}</h3>
        
        {/* Course features */}
        <div className="course-features">
          <div className="feature-item">
            <GiGraduateCap className="feature-icon" />
            <span className="feature-text">40 Seats</span>
          </div>
          
          <div className="feature-item">
            <TbClockFilled className="feature-icon" />
            <span className="feature-text">{duration}</span>
          </div>
          
          <div className="feature-item">
            <GrCertificate className="feature-icon" />
            <span className="feature-text">Certificate Included</span>
          </div>
        </div>
        
        {/* Download syllabus button */}
        <div className="course-action">
          <Link href={brochureUrl} target="_blank">
            <button className="syllabus-button">
              Download Syllabus
              <RxDownload className="syllabus-icon" />
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default OurCourseCard;
