"use client";

import React, { useRef, useEffect, useState } from "react";
import { FaHeadset, FaGraduationCap, FaLaptopCode, FaArrowRight, FaCheckCircle } from "react-icons/fa";
import { MdSupportAgent, MdOutlineSpeed } from "react-icons/md";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import axios from "axios";
import { prodServerUrl } from "@/app/utils/globals";
import "./lead-form.css";

// Register GSAP plugins
if (typeof window !== "undefined") {
  gsap.registerPlugin(ScrollTrigger);
}

const LeadFormSection = () => {
  const sectionRef = useRef(null);
  const contentRef = useRef(null);
  const formRef = useRef(null);
  const featureItemsRef = useRef(null);
  const [disable, setDisable] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
  });
  const [error, setError] = useState("");

  // Animation setup
  useEffect(() => {
    const ctx = gsap.context(() => {
      // Content animation
      gsap.fromTo(
        contentRef.current.children,
        { y: 30, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.8,
          stagger: 0.2,
          ease: "power3.out",
          scrollTrigger: {
            trigger: contentRef.current,
            start: "top 80%",
          },
        }
      );

      // Form animation
      gsap.fromTo(
        formRef.current,
        { y: 30, opacity: 0 },
        {
          y: 0,
          opacity: 1,
          duration: 0.8,
          delay: 0.3,
          ease: "power3.out",
          scrollTrigger: {
            trigger: formRef.current,
            start: "top 80%",
          },
        }
      );
      
      // Feature items animation
      if (featureItemsRef.current) {
        gsap.fromTo(
          featureItemsRef.current.children,
          { x: -20, opacity: 0 },
          {
            x: 0,
            opacity: 1,
            duration: 0.5,
            stagger: 0.15,
            ease: "power2.out",
            scrollTrigger: {
              trigger: featureItemsRef.current,
              start: "top 80%",
            },
          }
        );
      }
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { name, email, phone } = formData;

    if (!name || !email || !phone) {
      setError("All fields are required");
      return;
    }

    try {
      setDisable(true);
      const res = await axios.post(`${prodServerUrl}/lead-forms`, {
        name,
        email,
        phone,
      });

      if (res.status === 201) {
        setError("");
        setFormData({
          name: "",
          email: "",
          phone: "",
        });
        alert("We have received your query. You will be contacted soon");
      }
    } catch (error) {
      console.log(error);
      setError("Something went wrong. Please try again.");
    } finally {
      setDisable(false);
    }
  };

  return (
    <section
      ref={sectionRef}
      className="lead-form-section py-16 bg-cover bg-center bg-bg-leadForm relative"
    >
      {/* Circuit pattern overlay */}
      <div className="circuit-overlay"></div>

      {/* Glowing dots */}
      <div className="glowing-dots">
        <div className="glowing-dot"></div>
        <div className="glowing-dot"></div>
        <div className="glowing-dot"></div>
        <div className="glowing-dot"></div>
        <div className="glowing-dot"></div>
        <div className="glowing-dot"></div>
        <div className="glowing-dot"></div>
      </div>

      <div className="container px-6 mx-auto relative z-10">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
          {/* Content */}
          <div ref={contentRef} className="lead-content text-white">
            <h2 className="lead-heading md:text-left text-center">
              Get a Callback for a <span className="highlight">FREE</span> consultation on <span className="highlight">various courses</span>
            </h2>
            
            <p className="lead-description md:text-left text-center">
              Launch your tech career! Fill out the form to explore in-demand online courses in coding, 
              data analysis, digital marketing and many more. Get matched with a course advisor for a 
              free personalized plan.
            </p>

            {/* Feature items displayed as a list with icons on the left */}
            <div ref={featureItemsRef} className="feature-items-list mt-8">
              <div className="feature-item-row">
                <div className="feature-item-icon">
                  <FaHeadset className="icon" />
                </div>
                <div className="feature-item-text">One-on-one consultation with experts</div>
              </div>
              
              <div className="feature-item-row">
                <div className="feature-item-icon">
                  <FaGraduationCap className="icon" />
                </div>
                <div className="feature-item-text">Personalized course recommendations</div>
              </div>
              
              <div className="feature-item-row">
                <div className="feature-item-icon">
                  <MdOutlineSpeed className="icon" />
                </div>
                <div className="feature-item-text">Quick response within 24 hours</div>
              </div>
            </div>
          </div>

          {/* Form */}
          <div ref={formRef} className="lead-form-container">
            <h3 className="text-xl font-semibold text-white mb-6 text-center">
              Request a Callback
            </h3>

            {error && (
              <div className="bg-red-500/20 text-red-200 text-sm p-3 rounded-lg mb-4">
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Your Name"
                className="form-input"
                required
              />
              
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Email"
                className="form-input"
                required
              />
              
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Phone"
                className="form-input"
                required
              />

              <div className="text-center">
                <button
                  type="submit"
                  disabled={disable}
                  className="submit-button"
                >
                  {disable ? "Submitting..." : "Submit"} 
                  <FaArrowRight className="submit-button-icon ml-2" />
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LeadFormSection;
