"use client";

import React, { useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { FaCheckCircle } from "react-icons/fa";
import "./about-us.css";

const AboutUsSection = () => {
  const sectionRef = useRef(null);
  const imageRef = useRef(null);
  const statsRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    // Register ScrollTrigger inside useEffect
    gsap.registerPlugin(ScrollTrigger);

    const ctx = gsap.context(() => {
      // Image animation
      gsap.fromTo(imageRef.current, 
        { x: -100, opacity: 0 },
        {
          scrollTrigger: {
            trigger: imageRef.current,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse"
          },
          x: 0,
          opacity: 1,
          duration: 1,
          ease: "power3.out"
        }
      );

      // Stats counter animation
      const numberElement = statsRef.current?.querySelector(".stats-number");
      if (numberElement) {
        gsap.fromTo(numberElement,
          { innerHTML: "0" },
          {
            scrollTrigger: {
              trigger: statsRef.current,
              start: "top 80%",
              end: "bottom 20%",
              toggleActions: "play none none reverse"
            },
            innerHTML: "5",
            duration: 2,
            snap: { innerHTML: 1 },
            ease: "power1.inOut"
          }
        );
      }

      // Content fade in animation
      const contentElements = contentRef.current?.children;
      if (contentElements) {
        gsap.fromTo(contentElements,
          { y: 50, opacity: 0 },
          {
            scrollTrigger: {
              trigger: contentRef.current,
              start: "top 70%",
              end: "bottom 20%",
              toggleActions: "play none none reverse"
            },
            y: 0,
            opacity: 1,
            duration: 0.8,
            stagger: 0.2,
            ease: "power3.out"
          }
        );
      }
    }, sectionRef); // Scope all GSAP animations to the section

    // Cleanup
    return () => ctx.revert();
  }, []);

  return (
    <div ref={sectionRef} className="about-section py-20 overflow-hidden">
      {/* Decorative blobs */}
      <div className="decorative-blob blob-1"></div>
      <div className="decorative-blob blob-2"></div>
      
      <div className="container px-6 mx-auto max-w-7xl about-content">
        <div className="grid lg:grid-cols-2 gap-12 items-center">
          {/* Left side - Images */}
          <div ref={imageRef} className="image-container">
            <div className="relative z-10 space-y-8">
              <div className="relative image-wrapper">
                <Image
                  className="about-image"
                  src="/images/about-left.jpg"
                  width={500}
                  height={400}
                  alt="Students learning together"
                  priority
                />
                <div ref={statsRef} className="stats-badge">
                  <span className="stats-number">5</span>
                  <p className="stats-text">Years of Excellence</p>
                </div>
              </div>
              <div className="relative ml-auto w-3/4 image-wrapper">
                <Image
                  className="about-image"
                  src="/images/about-right.jpg"
                  width={400}
                  height={300}
                  alt="Online learning experience"
                  priority
                />
              </div>
            </div>
          </div>

          {/* Right side - Content */}
          <div ref={contentRef} className="space-y-6 lg:pl-12">
            <div className="section-tag">
              GET TO KNOW MORE
            </div>
            
            <h2 className="section-title">
              Grow your skills and learn with us from <span>everywhere!</span>
            </h2>

            <p className="section-description">
              We empower learners with in-demand technical skills through flexible, online courses. Master coding, data analysis, or IT fundamentals at your own pace. Expert instructors and real-world projects prepare you for success in today's job market.
            </p>

            <div className="feature-grid">
              <div className="feature-item">
                <div className="feature-header">
                  <FaCheckCircle className="feature-icon" />
                  <span className="feature-title">Expert Instructors</span>
                </div>
                <p className="feature-description">Learn from industry professionals</p>
              </div>
              <div className="feature-item">
                <div className="feature-header">
                  <FaCheckCircle className="feature-icon" />
                  <span className="feature-title">Flexible Learning</span>
                </div>
                <p className="feature-description">Study at your own pace</p>
              </div>
              <div className="feature-item">
                <div className="feature-header">
                  <FaCheckCircle className="feature-icon" />
                  <span className="feature-title">Practical Projects</span>
                </div>
                <p className="feature-description">Build real-world applications</p>
              </div>
              <div className="feature-item">
                <div className="feature-header">
                  <FaCheckCircle className="feature-icon" />
                  <span className="feature-title">Career Support</span>
                </div>
                <p className="feature-description">Get placement assistance</p>
              </div>
            </div>

            <div className="pt-4">
              <Link href="/about">
                <div className="about-cta-button">
                  Explore More
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUsSection;
