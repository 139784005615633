"use client";

import React, { useEffect, useRef } from "react";
import { TiTick } from "react-icons/ti";
import { FaBriefcase, FaCertificate, FaLaptopCode, FaChartLine, FaPen } from "react-icons/fa";
import Link from "next/link";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";

const Internship = () => {
  const sectionRef = useRef(null);
  const contentRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const ctx = gsap.context(() => {
      // Animate the content
      gsap.fromTo(contentRef.current.children,
        { y: 50, opacity: 0 },
        {
          scrollTrigger: {
            trigger: contentRef.current,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse"
          },
          y: 0,
          opacity: 1,
          duration: 0.8,
          stagger: 0.2,
          ease: "power3.out"
        }
      );
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  return (
    <div ref={sectionRef} className="relative overflow-hidden">
      {/* Main Section */}
      <div className="relative bg-gradient-to-br from-blue-900 via-blue-800 to-blue-900 py-20">
        {/* Background Pattern */}
        {/* <div className="absolute inset-0 bg-[url('/images/grid.svg')] opacity-20"></div> */}
        
        <div className="container mx-auto px-6 max-w-7xl relative z-10">
          <div ref={contentRef} className="space-y-12">
            {/* Header */}
            <div className="text-center space-y-4">
              <span className="inline-block bg-blue-500/20 text-blue-300 text-sm font-semibold px-4 py-1 rounded-full">
                INTERNSHIP OPPORTUNITIES
              </span>
              <h2 className="text-4xl md:text-5xl font-bold text-white">
                Gain Real-World Experience
              </h2>
              <p className="text-blue-100 text-lg max-w-2xl mx-auto">
                Complete any course at Vidyastu and get guaranteed internship opportunities to kickstart your career
              </p>
            </div>

            {/* Company Section */}
            <div className="bg-white/10 backdrop-blur-sm rounded-2xl p-8 md:p-12">
              <div className="grid md:grid-cols-2 gap-12 items-center">
                {/* Left: Company Info */}
                <div className="space-y-6">
                  <Link href="https://sisyphusinfotech.com/" target="_blank" className="inline-block">
                    <div className="group bg-white/5 hover:bg-white/10 backdrop-blur-sm border border-white/10 rounded-xl p-6 transition-all duration-300">
                      <div className="flex items-center space-x-4">
                        <img
                          src="./images/sisyphus.png"
                          alt="Sisyphus Infotech"
                          className="w-16 h-16 rounded-lg bg-white"
                        />
                        <div>
                          <h3 className="text-xl font-semibold text-white">Sisyphus Infotech</h3>
                          <p className="text-blue-200">Technology Partner</p>
                        </div>
                      </div>
                    </div>
                  </Link>

                  <div className="space-y-4">
                    <h4 className="text-2xl font-semibold text-white">Available Internships</h4>
                    <div className="grid grid-cols-1 gap-4">
                      <div className="flex items-center space-x-3 bg-white/5 rounded-lg p-4">
                        <FaLaptopCode className="text-yellow text-2xl" />
                        <span className="text-white">Web Development</span>
                      </div>
                      <div className="flex items-center space-x-3 bg-white/5 rounded-lg p-4">
                        <FaChartLine className="text-yellow text-2xl" />
                        <span className="text-white">Digital Marketing</span>
                      </div>
                      <div className="flex items-center space-x-3 bg-white/5 rounded-lg p-4">
                        <FaPen className="text-yellow text-2xl" />
                        <span className="text-white">News Content Writing</span>
                      </div>
                    </div>
                  </div>
                </div>

                {/* Right: Benefits */}
                <div className="space-y-6">
                  <div className="bg-green-500/10 border border-green-500/20 rounded-lg p-4 mb-6">
                    <p className="text-green-300 text-sm font-medium">✨ Complete any course at Vidyastu and get guaranteed internship placement</p>
                  </div>

                  <h4 className="text-2xl font-semibold text-white">What You'll Get</h4>
                  <div className="grid gap-4">
                    <div className="flex items-start space-x-3 bg-white/5 rounded-lg p-4">
                      <FaBriefcase className="text-yellow text-xl mt-1" />
                      <div>
                        <h5 className="text-white font-medium">Live Projects</h5>
                        <p className="text-blue-200 text-sm">Work on real client projects</p>
                      </div>
                    </div>
                    <div className="flex items-start space-x-3 bg-white/5 rounded-lg p-4">
                      <FaCertificate className="text-yellow text-xl mt-1" />
                      <div>
                        <h5 className="text-white font-medium">Certification</h5>
                        <p className="text-blue-200 text-sm">Industry-recognized certificates</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Internship;
