import Link from "next/link";
import { FaStar, FaPlay, FaYoutube } from "react-icons/fa";
import { HiOutlineArrowNarrowRight } from "react-icons/hi";
import { MdOutlineOndemandVideo } from "react-icons/md";
import './free-courses.css';

const OurFreeCourseCard = ({
  thumbnail,
  discountedPrice,
  title,
  resourceUrl,
  faculty
}) => {
  return (
    <div className="w-full max-w-[320px] bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 course-card gradient-border">
      {/* Card Header with Image */}
      <div className="relative">
        <div className="absolute inset-0 bg-gradient-to-t from-blue-900/80 to-transparent z-10"></div>
        <img
          src={thumbnail}
          alt={title}
          className="w-full h-48 object-cover"
        />
        {/* Free Badge */}
        <div className="absolute top-4 left-4 z-20">
          <span className="bg-yellow px-3 py-1 rounded-full text-xs font-semibold uppercase tracking-wide shadow-md">
            Free
          </span>
        </div>
        {/* YouTube Icon */}
        <div className="absolute bottom-4 right-4 z-20">
          <FaYoutube className="text-red-600 text-3xl bg-white rounded-full p-1 youtube-icon" />
        </div>
      </div>

      {/* Card Content */}
      <div className="p-5 space-y-4">
        {/* Title */}
        <h3 className="font-bold text-gray-800 text-lg line-clamp-2 h-14">
          {title}
        </h3>

        {/* Faculty */}
        {faculty && (
          <div className="flex items-center text-sm text-gray-600">
            <span className="font-medium">Instructor:</span>
            <span className="ml-2 text-gray-700 truncate">{faculty}</span>
          </div>
        )}

        {/* Rating */}
        <div className="flex items-center">
          <div className="flex">
            {[...Array(5)].map((_, i) => (
              <FaStar key={i} className="text-amber-400 w-4 h-4" />
            ))}
          </div>
          <span className="ml-2 text-xs text-gray-600">5.0 (Free Course)</span>
        </div>

        {/* Course Stats */}
        <div className="flex items-center text-sm text-gray-600">
          <MdOutlineOndemandVideo className="mr-1" />
          <span>Full Playlist</span>
        </div>

        {/* Action Button */}
        <Link
          href={resourceUrl}
          target="_blank"
          className="group flex items-center justify-center w-full bg-gradient-to-r from-blue-600 to-blue-700 hover:from-blue-700 hover:to-blue-800 text-white py-3 px-4 rounded-lg font-medium transition-all duration-300 mt-2 learn-btn"
        >
          <span>Start Learning</span>
          <HiOutlineArrowNarrowRight className="ml-2 group-hover:translate-x-1 transition-transform duration-300" />
        </Link>
      </div>
    </div>
  );
};

export default OurFreeCourseCard;
