import React from "react";
import Image from "next/image";
import CallToActionButton from "@/components/layout/Button/CallToActionButton";

const CallToActionSection = () => {
  return (
    <div className="container mx-auto my-16 px-4 md:px-6 max-w-7xl">
      <div className="flex flex-col md:flex-row rounded-2xl shadow-lg overflow-hidden">
        {/* Left Content Section */}
        <div className="bg-gradient-to-br from-[#d9f7ee] to-[#BCF2E1] p-8 md:p-12 flex-1 relative">
          {/* Decorative elements */}
          <div className="absolute top-0 right-0 w-24 h-24 bg-yellow-100 rounded-full -mt-6 -mr-6 opacity-50"></div>
          <div className="absolute bottom-0 left-0 w-16 h-16 bg-teal-100 rounded-full -mb-4 -ml-4 opacity-30"></div>
          
          {/* Badge and icons row */}
          <div className="flex items-center mb-6 relative z-10">
            <div className="flex justify-center items-center w-[80px] h-[80px] bg-yellow-400 rounded-full shadow-md">
              <Image
                className="block w-[50px]"
                src="/images/msme.png"
                width={50}
                height={50}
                alt="msme logo"
                priority
              />
            </div>
            <Image
              className="block -ml-6 transform hover:rotate-12 transition-transform"
              src="/images/badge.png"
              width="90"
              height="90"
              alt="certification badge"
              priority
            />
          </div>
          
          {/* Content */}
          <div className="space-y-4">
            <h3 className="text-lg md:text-xl font-bold text-teal-800 tracking-wide">
              BOOST YOUR SKILL
            </h3>
            <div className="w-16 h-1 bg-gradient-to-r from-teal-500 to-teal-400 rounded-full"></div>
            <p className="my-4 text-sm md:text-base text-gray-700 max-w-md">
              Get your MSME Registered Online Certificate from{" "}
              <span className="text-teal-700 font-semibold">VIDYASTU</span>
              {" "}and unlock new opportunities for your career growth.
            </p>
            
            <div className="mt-6">
              <CallToActionButton btnText="Know More" link="/about" />
            </div>
          </div>
        </div>
        
        {/* Right Image Section */}
        <div className="relative w-full md:w-[350px] h-[250px] md:h-auto overflow-hidden">
          <Image
            className="object-cover w-full h-full transform hover:scale-105 transition-transform duration-500"
            src="/images/certificate.png"
            fill
            sizes="(max-width: 768px) 100vw, 350px"
            priority
            alt="MSME certificate sample"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black/20 to-transparent"></div>
        </div>
      </div>
    </div>
  );
};

export default CallToActionSection;
