import React, { useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { FaCalendarAlt, FaClock, FaGraduationCap, FaChalkboardTeacher, FaArrowRight } from "react-icons/fa";
import { BsLaptop } from "react-icons/bs";
import { HiOutlineExternalLink } from "react-icons/hi";

const AdmissionCard = ({
  title,
  image,
  slug,
  description,
  duration,
  mode,
  faculty,
  syllabus,
}) => {
  const [imgError, setImgError] = useState(false);
  const fallbackImage = "/images/certificate.png"; // Fallback image if the course image fails to load

  return (
    <div className="admission-card w-full max-w-sm">
      {/* Course badge - Shows it's open for admission */}
      <div className="admission-badge">Admission Open</div>
      
      {/* Course image with error handling */}
      <div className="admission-image bg-gray-100">
        <Image
          src={imgError ? fallbackImage : image}
          alt={title}
          fill
          sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
          style={{ objectFit: "cover" }}
          priority
          onError={() => setImgError(true)}
        />
      </div>
      
      {/* Course content */}
      <div className="admission-content">
        {/* Course title */}
        <h3 className="admission-title">{title}</h3>
        
        {/* Admission status with pulsing dot */}
        <div className="admission-status">
          <div className="admission-status-dot"></div>
          <span className="admission-status-text">Registration Open</span>
        </div>
        
        {/* Course description */}
        <p className="admission-description">{description}</p>
        
        {/* Course information */}
        <div className="admission-info">
          
          {/* Duration */}
          {duration && (
            <div className="admission-info-item">
              <FaClock className="admission-info-icon" />
              <span>Duration: {duration}</span>
            </div>
          )}
          
          {/* Mode */}
          {mode && (
            <div className="admission-info-item">
              <BsLaptop className="admission-info-icon" />
              <span>Mode: {mode}</span>
            </div>
          )}
          
          {/* Faculty */}
          {faculty && (
            <div className="admission-info-item">
              <FaChalkboardTeacher className="admission-info-icon" />
              <span>Faculty: {faculty}</span>
            </div>
          )}
        </div>
        
        {/* Action buttons */}
        <div className="admission-actions">
          <Link 
            href={`/premium-courses/${slug}`} 
            className="admission-btn admission-btn-primary"
          >
            Enroll Now <FaArrowRight />
          </Link>
          
          {syllabus && (
            <Link 
              href={syllabus} 
              className="admission-btn admission-btn-secondary"
              target="_blank"
            >
              View Syllabus <HiOutlineExternalLink />
            </Link>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdmissionCard; 