"use client";
import AboutUsSection from "../components/home/about-us/AboutUsSection";
import CallToActionSection from "../components/home/call-to-action/CallToActionSection";
import CollaboratedCompanySection from "../components/home/collaborated-company/CollaboratedCompanySection";
import EventSection from "@/components/home/event/EventSection";
import UpcomingAdmissionsSection from "@/components/home/upcoming-admissions/UpcomingAdmissionsSection";
import FacultySection from "../components/home/faculty/FacultySection";
import HeroSection from "../components/home/hero/HeroSection";
import KeyFeaturesSection from "../components/home/key-features/KeyFeaturesSection";
import LeadFormSection from "../components/home/lead-form/LeadFormSection";
import OurCoursesSection from "../components/home/our-courses/OurCoursesSection";
import SuccessStorySection from "../components/home/success-stories/SuccessStorySection";
import FreeCourses from "../components/home/free-courses/FreeCourses";
import Founder from "../components/home/founder/Founder";
import Internship from "@/components/home/internship/Internship";
import WhatsAppButton from "@/components/common/WhatsAppButton";
import DownloadOurApp from "@/components/common/DownloadOurApp";
import NewsChannel from "@/components/home/news/NewsChannel";
import CustomHead from "@/utils/CustomHead";
import { Slider } from "@/components/home/hero-slider/Slider";
import { CardReveal } from "@/components/home/cards/CardReveal";
import RequestCallback from "@/components/common/RequestCallback";
import { useRouter } from "next/navigation";
import { useState, useEffect } from "react";
import { prodServerUrl } from "@/app/utils/globals";
import { IoCloseCircleOutline } from "react-icons/io5";
import Image from "next/image";
import { Review } from "@/components/home/reviews/Review";
import axios from "axios";

export default function page() {
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    // Set a timeout to delay the popup display
    const timeout = setTimeout(() => {
      setShowPopup(true);
    }, 5000); // Display popup after 1 second

    // Clear the timeout if the component unmounts
    return () => clearTimeout(timeout);
  }, []);

  const [disable, setDisable] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    selectedCourse: "",
  });
  const [error, setError] = useState("");
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const router = useRouter();
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!name && !phone && !selectedCourse) {
      alert("All Fields Are Necessary");
      return;
    }

    try {
      const res = await axios.post(`${prodServerUrl}/hero-forms`, formData);

      if (res.status === 201) {
        alert("We have received your query. You will be contacted soon");
        setError("");
        setFormData({
          name: "",
          phone: "",
          selectedCourse: "",
        });
        router.push("/thank-you");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div>
        {/* <Slider /> */}
        <HeroSection />
        {/* <KeyFeaturesSection /> */}
        <AboutUsSection />
        <EventSection />
        <UpcomingAdmissionsSection />
        <CollaboratedCompanySection />
        {/* <OurCoursesSection /> */}
        <LeadFormSection />
        <FreeCourses />
        <FacultySection />
        {/* <CardReveal /> */}
        {/* <NewsChannel /> */}
        <CallToActionSection />
        {/* <Founder /> */}
        {/* <SuccessStorySection /> */}
        {/* <Review /> */}
        <Internship/>
        <WhatsAppButton />
        {/* <DownloadOurApp /> */}
        {/* <RequestCallback /> */}
      </div>

      {showPopup && (
        <div className="popup-overlay">
          <div className="relative max-w-md w-full mx-auto">
            {/* Decorative elements */}
            <div className="absolute -top-10 -right-10 w-40 h-40 bg-blue-500 rounded-full filter blur-3xl opacity-20"></div>
            <div className="absolute -bottom-10 -left-10 w-40 h-40 bg-yellow-500 rounded-full filter blur-3xl opacity-20"></div>
            
            <form
              onSubmit={handleSubmit}
              className="relative z-10 bg-gradient-to-br from-gray-900/90 to-gray-800/90 p-8 rounded-xl backdrop-blur-xl border border-gray-700 shadow-2xl"
            >
              {/* Logo and branding */}
              <div className="flex flex-col justify-center items-center mb-6">
                <h5 className="uppercase tracking-widest text-blue-500 font-bold text-3xl">
                  vidya<span className="text-yellow">s</span>tu
                </h5>
                <p className="text-blue-400 mt-2 text-sm font-medium">
                  Your Online Finishing School
                </p>
              </div>

              {/* Course information */}
              <div className="bg-gray-800/50 p-4 rounded-lg mb-6 border-l-4 border-yellow">
                <h5 className="font-Poppins text-md font-bold text-white mb-2">
                  Admission is Open for{" "}
                  <span className="text-lg text-yellow">Cyber Security and Ethical Hacking</span>{" , "} 
                  <span className="text-lg text-green-400">Artificial Intelligence and Machine Learning</span> and{" "}
                  <span className="text-lg text-blue-400">Java Springboot</span>.
                </h5>
              </div>

              {/* Form fields with improved styling */}
              <div className="space-y-4">
                <div>
                  <select
                    id="selectedCourse"
                    name="selectedCourse"
                    value={formData.selectedCourse}
                    onChange={handleChange}
                    className="w-full bg-gray-700/50 border border-gray-600 rounded-lg py-3 px-4 text-gray-200 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 text-sm"
                  >
                    <option value="">Select a course</option>
                    <option value="cyber security">Cyber Security and Ethical Hacking</option>
                    <option value="java springboot">Java Springboot</option>
                    <option value="ai & ml">Artificial Intelligence and Machine Learning</option>
                    <option value="ui ux">UI/UX & Graphic Design</option>
                    <option value="digital marketing">Digital Marketing</option>
                    {/* <option value="data science">Data Science & Analytics</option> */}
                    {/* <option value="content writing">Content Writing</option> */}
                    {/* <option value="web development">Full Stack Web Development</option> */}
                    {/* <option value="springboot">Java Springboot</option> */}
                  </select>
                </div>
                
                <div>
                  <input
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    type="text"
                    className="w-full bg-gray-700/50 border border-gray-600 rounded-lg py-3 px-4 text-gray-200 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 text-sm"
                    placeholder="Your Name"
                    required
                  />
                </div>
                
                <div>
                  <input
                    id="phone"
                    name="phone"
                    type="number"
                    value={formData.phone}
                    onChange={handleChange}
                    className="w-full bg-gray-700/50 border border-gray-600 rounded-lg py-3 px-4 text-gray-200 placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 text-sm"
                    placeholder="Your Phone"
                    required
                  />
                </div>
              </div>

              {/* Action buttons */}
              <div className="flex flex-row justify-between items-center gap-4 mt-6">
                <button
                  className="text-sm font-medium px-5 py-3 w-full rounded-lg bg-gray-700 text-white border border-gray-600 hover:bg-gray-600 transition-all duration-200 flex items-center justify-center"
                  onClick={() => setShowPopup(false)}
                  type="button"
                >
                  Close
                </button>
                <button
                  className={`${
                    disable ? "bg-gray-600" : "bg-gradient-to-r from-blue-700 to-blue-900 hover:from-blue-800 hover:to-blue-950"
                  } text-sm font-medium px-5 py-3 w-full text-white rounded-lg transition-all duration-200 flex items-center justify-center`}
                  type="submit"
                  disabled={disable ? true : false}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}
