import React, { useEffect, useRef } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import { FaBriefcase } from "react-icons/fa";
import { testimonials } from "@/data/data";
import "./alumni.css";

const CollaboratedCompanySection = () => {
  const sectionRef = useRef(null);
  const headingRef = useRef(null);
  const logosRef = useRef(null);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    const ctx = gsap.context(() => {
      // Animate the heading
      gsap.fromTo(headingRef.current,
        { y: 30, opacity: 0 },
        {
          scrollTrigger: {
            trigger: headingRef.current,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse"
          },
          y: 0,
          opacity: 1,
          duration: 0.8,
          ease: "power3.out"
        }
      );

      // Animate the logos
      gsap.fromTo(logosRef.current,
        { y: 50, opacity: 0 },
        {
          scrollTrigger: {
            trigger: logosRef.current,
            start: "top 80%",
            end: "bottom 20%",
            toggleActions: "play none none reverse"
          },
          y: 0,
          opacity: 1,
          duration: 1,
          ease: "power3.out"
        }
      );
    }, sectionRef);

    return () => ctx.revert();
  }, []);

  // Split testimonials into two rows for the marquee
  const firstRow = testimonials.slice(0, Math.ceil(testimonials.length / 2));
  const secondRow = testimonials.slice(Math.ceil(testimonials.length / 2));

  return (
    <section ref={sectionRef} className="alumni-section py-16 relative overflow-hidden">
      <div className="container mx-auto px-4 md:px-8 max-w-7xl relative z-10">
        {/* Section Header */}
        <div ref={headingRef} className="text-center mb-12 section-header">
          <div className="flex items-center justify-center mb-4">
            <div className="h-[1px] w-12 bg-blue-600"></div>
            <span className="mx-4 text-blue-600 font-semibold text-sm uppercase tracking-wider">Success stories</span>
            <div className="h-[1px] w-12 bg-blue-600"></div>
          </div>
          
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4 flex items-center justify-center">
            <FaBriefcase className="text-blue-600 mr-3" />
            Our Alumni Works At
          </h2>
          
          <p className="text-gray-600 max-w-2xl mx-auto">
            Grow your skills and learn with us from everywhere
          </p>
          
          <div className="mx-auto accent-underline mt-4"></div>
        </div>

        {/* Logos Section */}
        <div ref={logosRef} className="alumni-logo-container">
          {/* First row - left to right */}
          <div className="marquee-container mb-8">
            <div className="marquee">
              {firstRow.map((logo) => (
                <div key={logo.id} className="alumni-logo-item">
                  <img src={logo.title} alt="Company Logo" />
                </div>
              ))}
            </div>
            <div className="marquee">
              {firstRow.map((logo) => (
                <div key={`dup-${logo.id}`} className="alumni-logo-item">
                  <img src={logo.title} alt="Company Logo" />
                </div>
              ))}
            </div>
          </div>
          
          {/* Second row - right to left */}
          <div className="marquee-container">
            <div className="marquee marquee-reverse">
              {secondRow.map((logo) => (
                <div key={logo.id} className="alumni-logo-item">
                  <img src={logo.title} alt="Company Logo" />
                </div>
              ))}
            </div>
            <div className="marquee marquee-reverse">
              {secondRow.map((logo) => (
                <div key={`dup-${logo.id}`} className="alumni-logo-item">
                  <img src={logo.title} alt="Company Logo" />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CollaboratedCompanySection;
