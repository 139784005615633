import React, { useEffect, useRef } from 'react';
import Link from "next/link";
import OurCourseCards from "./OurCourseCards";
import { FaArrowRight } from 'react-icons/fa';
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger';
import './popular-courses.css';

// Register ScrollTrigger plugin
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
}

const OurCoursesSection = () => {
  const sectionRef = useRef(null);
  const headerRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Animation for the section header
      gsap.fromTo(
        headerRef.current,
        { 
          y: 50, 
          opacity: 0 
        },
        { 
          y: 0, 
          opacity: 1, 
          duration: 0.8, 
          ease: 'power3.out',
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top 80%',
          }
        }
      );
    }
  }, []);

  return (
    <section ref={sectionRef} className="courses-section py-16 px-4 md:px-8">
      {/* Decorative elements */}
      <div className="max-w-7xl mx-auto">
        {/* Section header */}
        <div ref={headerRef} className="section-header text-center mb-12">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-800">POPULAR COURSES</h2>
          <div className="accent-underline mx-auto"></div>
          <p className="mt-4 text-gray-600 max-w-2xl mx-auto">
            Explore our most popular courses designed to help you master in-demand skills and advance your career
          </p>
        </div>

        {/* Course cards container */}
        <div className="course-card-container">
          <OurCourseCards />
          
          {/* View all courses button */}
          <div className="text-center mt-8">
            <Link href="/premium-courses">
              <button className="view-all-button">
                View All Courses
                <FaArrowRight className="view-all-icon" />
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OurCoursesSection;
