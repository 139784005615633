"use client";
import React from "react";
import { FaLinkedin, FaQuoteLeft } from "react-icons/fa";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import {
  Navigation,
  Pagination,
  Autoplay,
  EffectCards,
} from "swiper/modules";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/effect-cards";
import faculties from "../faculty/data/faculties";
import Link from "next/link";
import "./swiper-slider.css";

const SwiperSlider = () => {
  return (
    <div className="faculty-swiper">
      <Swiper
        breakpoints={{
          340: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
        navigation={true}
        pagination={{
          clickable: true,
          dynamicBullets: true,
        }}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        modules={[Navigation, Pagination, Autoplay]}
        className="max-w-full px-4 pb-16"
      >
        {faculties.map((faculty) => (
          <SwiperSlide key={faculty.id} className="h-auto flex">
            <div className="faculty-card bg-white rounded-xl overflow-hidden shadow-lg p-6 flex flex-col w-full h-full">
              <div className="flex flex-col items-center mb-6">
                {/* Faculty Image */}
                <div className="relative mb-4">
                  <div className="absolute inset-0 rounded-full bg-gradient-to-r from-blue-500 to-blue-600 blur-sm opacity-30 scale-110"></div>
                  <img 
                    src={faculty.image} 
                    alt={faculty.title} 
                    className="faculty-image w-24 h-24 md:w-32 md:h-32 rounded-full object-cover relative z-10" 
                  />
                </div>
                
                {/* Faculty Name & Role */}
                <h3 className="text-lg font-bold text-gray-800 text-center">{faculty.title}</h3>
                {/* <p className="text-blue-600 text-sm font-medium text-center">{faculty.description.split(' ')[0]}</p> */}
              </div>
              
              {/* Faculty Description */}
              <div className="flex-grow mb-6 relative">
                <FaQuoteLeft className="text-blue-100 text-4xl absolute -top-2 -left-1 quote-icon transition-transform duration-300" />
                <p className="text-gray-600 text-sm leading-relaxed relative z-10 pl-4 line-clamp-6">
                  {faculty.description}
                </p>
              </div>
              
              {/* Social Media */}
              <div className="flex justify-center mt-auto pt-4">
                {faculty.linkedinUrl && (
                  <Link 
                    href={faculty.linkedinUrl} 
                    target="_blank"
                    className="social-icon inline-flex items-center justify-center w-10 h-10 bg-blue-50 hover:bg-blue-100 rounded-full text-blue-600"
                  >
                    <FaLinkedin className="w-5 h-5" />
                  </Link>
                )}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default SwiperSlider;
