"use client";

import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { prodServerUrl } from "@/app/utils/globals";
import OurCourseCard from "./OurCourseCard";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Autoplay, EffectCards } from 'swiper/modules';
import gsap from 'gsap';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-cards';
import './CourseCarousel.css';

const OurCourseCards = () => {
  const didRunOnce = useRef(false);
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true);
  const swiperRef = useRef(null);

  const fetchCourses = async () => {
    try {
      setLoading(true);
      const { data } = await axios.get(`${prodServerUrl}/courses`, {
        headers: {
          "Content-Type": "application/json; charset=UTF-8",
        },
      });

      if (data.success === true) {
        setCourses(data.courses.filter(course => course.discountedPrice !== 0));
      }
    } catch (err) {
      console.log(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (!didRunOnce.current) {
      fetchCourses();
      didRunOnce.current = true;
    }
  }, []);

  useEffect(() => {
    if (swiperRef.current && !loading) {
      // Animate the swiper container when courses are loaded
      gsap.fromTo(
        swiperRef.current,
        { opacity: 0, y: 30 },
        { opacity: 1, y: 0, duration: 0.8, ease: 'power3.out' }
      );
    }
  }, [loading]);

  // Loading skeleton
  if (loading) {
    return (
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 animate-pulse">
        {[1, 2, 3].map((item) => (
          <div key={item} className="bg-white rounded-lg overflow-hidden shadow-md">
            <div className="h-48 bg-gray-200"></div>
            <div className="p-4">
              <div className="h-6 bg-gray-200 rounded w-3/4 mb-4"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2 mb-3"></div>
              <div className="h-4 bg-gray-200 rounded w-2/3 mb-3"></div>
              <div className="h-4 bg-gray-200 rounded w-1/2 mb-3"></div>
              <div className="h-10 bg-gray-200 rounded mt-6"></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  return (
    <div ref={swiperRef} className="w-full">
      <Swiper
        modules={[Navigation, Pagination, Autoplay, EffectCards]}
        spaceBetween={30}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        loop={true}
        autoplay={{
          delay: 4000,
          disableOnInteraction: false,
          pauseOnMouseEnter: true
        }}
        speed={800}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
        }}
        className="mySwiper"
      >
        {courses.map((course) => (
          <SwiperSlide key={course._id} className="pb-12 px-2 h-auto">
            <OurCourseCard
              title={course.name}
              thumbnail={course.thumbnail.url}
              discountedPrice={course.discountedPrice}
              brochureUrl={course.brochureUrl}
              duration={course.duration}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default OurCourseCards;
