"use client";

import React, { useEffect, useRef } from "react";
import AdmissionCard from "./AdmissionCard";
import "./admission.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/dist/ScrollTrigger";
import Link from "next/link";
import { FaGraduationCap, FaArrowRight } from "react-icons/fa";

// Register ScrollTrigger plugin
if (typeof window !== 'undefined') {
  gsap.registerPlugin(ScrollTrigger);
}

const UpcomingAdmissionsSection = () => {
  const sectionRef = useRef(null);
  const titleRef = useRef(null);
  const cardsRef = useRef(null);

  // Sample data for upcoming courses
  const upcomingCourses = [
    {
      id: 1,
      title: "Cyber Security and Ethical Hacking",
      slug: "cyber-security",
      image: "/images/courseone.jpg",
      description: "Master cybersecurity skills including network security, penetration testing, and ethical hacking techniques.",
      duration: "6 months",
      mode: "Online",
      faculty: "Abhranil Majumder",
      syllabus: "https://drive.google.com/file/d/1_J2qfHqmZtA9XQH4IrX3q-FFflhqrJtP/view"
    },
    {
      id: 2,
      title: "Artificial Intelligence and Machine Learning",
      slug: "data-science-and-analytics-using-ai-and-chat-gpt",
      image: "/images/coursetwo.jpg",
      description: "Learn the fundamentals of AI, ML algorithms, neural networks, and practical applications with Python.",
      duration: "6 months",
      mode: "Online",
      faculty: "Ushashi Chowdhury & Sayan Barik",
      syllabus: "https://drive.google.com/file/d/1BduJvCheP5U6wfBU52N1r2Q86S5_CC0U/view"
    },
    {
      id: 3,
      title: "Java Spring Boot Development",
      slug: "full-stack-web-development-with-java-spring-boot",
      image: "/images/coursethree.jpg",
      description: "Master enterprise application development with Java Spring Boot framework and microservices architecture.",
      duration: "5 months",
      mode: "Online",
      faculty: "Sayan Dey",
      syllabus: "https://drive.google.com/file/d/1Ya_KDH1dnYCtLzHwtSwdW3j7iyiNMFSG/view"
    }
  ];

  useEffect(() => {
    if (typeof window !== 'undefined') {
      // Animation for the section title
      gsap.fromTo(
        titleRef.current,
        { y: 30, opacity: 0 },
        { 
          y: 0, 
          opacity: 1, 
          duration: 0.8, 
          ease: 'power3.out',
          scrollTrigger: {
            trigger: sectionRef.current,
            start: 'top 80%',
          }
        }
      );

      // Animation for the cards
      if (cardsRef.current && cardsRef.current.children.length > 0) {
        gsap.fromTo(
          cardsRef.current.children,
          { y: 50, opacity: 0 },
          { 
            y: 0, 
            opacity: 1, 
            duration: 0.8, 
            stagger: 0.2,
            ease: 'power3.out',
            scrollTrigger: {
              trigger: cardsRef.current,
              start: 'top 80%',
            }
          }
        );
      }
    }
  }, []);

  return (
    <section ref={sectionRef} className="admissions-section">
      {/* Background decorative elements */}
      <div className="admissions-bg-circle-1"></div>
      <div className="admissions-bg-circle-2"></div>
      
      <div className="container mx-auto px-4 relative z-10">
        {/* Section header */}
        <div ref={titleRef} className="text-center mb-12">
          <div className="flex items-center justify-center mb-3">
            <span className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center mr-2">
              <FaGraduationCap className="text-blue-600" />
            </span>
            <h6 className="text-blue-600 font-semibold text-sm uppercase tracking-wider">Latest Admissions</h6>
          </div>
          
          <h2 className="text-3xl md:text-4xl font-bold text-gray-800 mb-4">
            Upcoming Course <span className="text-blue-600">Admissions</span>
          </h2>
          
          <p className="text-gray-600 max-w-2xl mx-auto">
            Enroll in our industry-focused courses and get MSME registered certificates to boost your career. Limited seats available!
          </p>
        </div>
        
        {/* Course cards - Centered grid container */}
        <div className="max-w-screen-xl mx-auto">
          <div ref={cardsRef} className="grid grid-cols-1 md:grid-cols-3 gap-8 justify-items-center">
            {upcomingCourses.map((course) => (
              <AdmissionCard
                key={course.id}
                title={course.title}
                image={course.image}
                slug={course.slug}
                description={course.description}
                duration={course.duration}
                mode={course.mode}
                faculty={course.faculty}
                syllabus={course.syllabus}
              />
            ))}
          </div>
        </div>
        
        {/* View all courses button */}
        <div className="mt-12 text-center">
          <Link 
            href="/premium-courses" 
            className="inline-flex items-center justify-center px-6 py-3 bg-blue-600 text-white font-medium rounded-lg shadow-md hover:bg-blue-700 transition-all duration-300 group"
          >
            View All Courses 
            <FaArrowRight className="ml-2 transform group-hover:translate-x-1 transition-transform duration-300" />
          </Link>
        </div>
      </div>
    </section>
  );
};

export default UpcomingAdmissionsSection; 